import { PageProps } from 'gatsby';
import { ContactData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import IconEnvelopeOpenText from '~/icons/EnvelopeOpenText';
import contact from '~/params/contact.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceContactUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, contactId },
}) => {
  const formName = 'contact';

  const model = new ContactData({
    espaceId,
    params: contact,
  });

  const handleSendInvitationOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    // const eventData = new EventData({ espaceId });
    // toast.promise(
    //   eventData.create({
    //     data: {
    //       object: {
    //         id: contactId,
    //       },
    //     },
    //     type: 'contact.invited',
    //   }),
    //   {
    //     error: "Erreur lors de l'envoi de l'invitation",
    //     pending: 'En cours de traitement',
    //     success: "L'invitation a été envoyé avec succès",
    //   },
    // );
  };

  return (
    <TemplateEspace
      collectionName="contacts"
      docId={contactId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          docId={contactId}
          itemPathnamePrefix={`/espaces/${espaceId}/contacts/`}
          menu={{
            right: [
              {
                icon: IconEnvelopeOpenText,
                label: 'Envoyer une invitation',
                onClick: handleSendInvitationOnClick,
              },
            ],
          }}
          model={model}
          name={formName}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceContactUpdate);
